<template>
  <div class="FormWrap">
    <mia-navback @back="back" :isShowSave="true" @submit="submitForm" />
    <div class="content" style="padding: 29px 20px">
      <el-form
        ref="searchForm"
        size="mini"
        :model="searchForm"
        :inline="true"
        :rules="searchRules"
        label-position="left"
      >
        <el-form-item label="供应商" prop="supplierId">
          <el-select
            v-model="searchForm.supplierId"
            placeholder="请选择"
            clearable
            filterable
          >
            <el-option
              v-for="item in supplierList"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="出库仓库" prop="wmsId">
          <el-select
            v-model="searchForm.wmsId"
            placeholder="请选择"
            clearable
            filterable
            @change="cleardata"
          >
            <el-option
              v-for="item in WmsList"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="经手人">
          <el-select
            v-model="searchForm.handler"
            placeholder="请选择"
            clearable
            filterable
          >
            <el-option
              v-for="item in userList"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="录单时间" prop="entryDate">
          <el-date-picker
            v-model="dateTime"
            type="datetime"
            format="yyyy-MM-dd HH:mm:ss"
            value-format="yyyy-MM-dd HH:mm:ss"
            :picker-options="pickerOptions"
            placeholder="选择录单时间"
          >
          </el-date-picker>
        </el-form-item>
        <el-form-item label="单号" prop="purchaseRefundNo">
          <el-input
            style="width: 100%"
            :disabled="isEdit"
            v-model="searchForm.purchaseRefundNo"
            onkeyup="this.value=this.value.replace(/[\u4E00-\u9FA5]/g,'')"
            placeholder="请选择"
            clearable
          ></el-input>
        </el-form-item>
      </el-form>
      <SalesreturnTable
        ref="Editable"
        :isPutStorage="false"
        :wmsId="searchForm.wmsId"
        :editForm="DetailData"
        :tableData="tableData"
        :isEdit="isEdit"
      />
    </div>
  </div>
</template>
<script>
import { GetSupplierList } from "@/api/supplier";
import { GetWmsList } from "@/api/warehouse";
import { getUserList } from "@/api/user";
import {
  AddPurchaseRefund,
  GetPurchaseRefundInfo,
  EditPurchaseRefund,
  checkPurchaseRefundNo,
} from "@/api/purchaseRefund";
import { datatimestatus, getDate, backUnit } from "@/until/auth.js";
import {
  SalesreturnTableGoods,
  batchGoods,
  commonGoods,
  SalesreturnGoods,
  SalesreturnBatchGoods,
  SerialGoods,
  SalesreturnSerialGoods,
  BatchandSerialGoods,
} from "@/until/statement.js";
export default {
  data() {
    return {
      searchForm: {
        supplierId: "",
        wmsId: "",
        handler: "",
        purchaseRefundNo: "",
        entryDate: "",
      },
      supplierList: [],
      WmsList: [],
      userList: [],
      dateTime: [],
      tableData: [],
      pickerOptions: null,
      searchRules: {
        supplierId: [
          { required: true, message: "请选择供应商", trigger: "change" },
        ],
        wmsId: [
          { required: true, message: "请选择入库仓库", trigger: "change" },
        ],
        purchaseRefundNo: [
          {
            min: 8,
            max: 32,
            message: "长度在 8 到 32 个字符",
            trigger: "blur",
          },
        ],
      },
      // 修改的时候的详情
      DetailData: null,
    };
  },
  props: {
    total: {
      type: Number,
      default: () => 0,
    },
    isEdit: {
      type: Boolean,
      default: () => false,
    },
    data: {
      type: Object,
      default: () => {},
    },
  },
  created() {
    this.getSupplier();
    this.getWmsList();
    this.getUser();
    this.pickerOptions = datatimestatus();
    this.tableData = new Array(10)
      .fill(new SalesreturnTableGoods())
      .map((val) => {
        return { ...val };
      });
    if (this.isEdit) {
      this.GetInfo(this.data.id);
    } else {
      this.dateTime = getDate();
      // 获取单号
      this.getRefundNo(this.dateTime);
    }
  },
  watch: {
    dateTime(val) {
      this.searchForm.entryDate = val;
      if (!this.isEdit) {
        this.getRefundNo(this.dateTime);
      }
    },
    isEdit(val) {
      this.isEdit = val;
    },
    data(val) {
      this.data = val;
    },
    DetailData(val) {
      this.DetailData = val;
    },
  },
  methods: {
    submitForm() {
      this.$refs["searchForm"].validate((valid) => {
        if (valid) {
          const list = this.$refs.Editable.tableList.filter((val) => {
            return val.productId;
          });
          if (list.length !== 0) {
            this.filtrationlist = this.filtrationData(list);
            if (
              this.filtrationlist &&
              this.filtrationlist.length !== 0 &&
              this.filtrationState
            ) {
              this.disposeData(this.filtrationlist);
            }
          } else {
            this.$message.error("请在入库单内添加有效商品数据");
          }
        } else {
          return false;
        }
      });
    },
    back() {
      this.$emit("back");
    },
    // 获取单号
    getRefundNo(date) {
      checkPurchaseRefundNo({ date: date }).then((res) => {
        if (res.code === 1) this.searchForm.purchaseRefundNo = res.data;
      });
    },
    // 供应商列表
    getSupplier() {
      GetSupplierList({}).then((res) => {
        if (res.code === 1) {
          this.supplierList = res.data.records;
        } else {
          this.supplierList = [];
        }
      });
    },
    // 仓库列表
    getWmsList() {
      GetWmsList({}).then((res) => {
        if (res.code === 1) {
          this.WmsList = res.data.records;
        } else {
          this.WmsList = [];
        }
      });
    },
    // 用户列表
    getUser() {
      getUserList({ status: 0 }).then((res) => {
        if (res.code === 1) {
          this.userList = res.data.records;
        } else {
          this.userList = [];
        }
      });
    },
    // 修改时获取退货单信息
    GetInfo(id) {
      GetPurchaseRefundInfo(id).then((res) => {
        if (res.code === 1) {
          this.DetailData = res.data;
          Object.keys(this.searchForm).forEach((key) => {
            if (this.DetailData[key] && this.DetailData[key] !== undefined) {
              this.searchForm[key] = this.DetailData[key];
            }
          });
          this.dateTime = this.searchForm.entryDate;
          this.SetupTable(this.DetailData.list);
        }
      });
    },
    // 转换成列表数据
    SetupTable(list) {
      const tabeData = JSON.parse(JSON.stringify(list));
      this.tableData = [];
      tabeData.forEach((v) => {
        let obj = null;
        // if(!v.isBatch && !v.isSerial ) {
        // 普通商品
        obj = {
          ...new SalesreturnGoods(
            v.id,
            v.productName,
            v.productId,
            v.productCode,
            "普通",
            v.unit,
            v.unitId,
            v.unitList,
            v.unitValue,
            v.inventoryNum,
            null,
            v.num,
            v.price,
            v.totalPrice
          ),
        };
        // }
        if (v.isBatch && !v.isSerial) {
          // 单批次商品
          obj = {
            ...new SalesreturnBatchGoods(
              v.id,
              v.productName,
              v.productId,
              v.productCode,
              "批次",
              v.batchNo,
              v.productionDate,
              v.expire,
              v.expireDate,
              v.unit,
              v.unitId,
              v.unitList,
              v.unitValue,
              v.inventoryNum,
              null,
              v.num,
              v.price,
              v.totalPrice
            ),
          };
        }
        if (!v.isBatch && v.isSerial) {
          obj = {
            ...new SalesreturnSerialGoods(
              v.id,
              v.productName,
              v.productId,
              v.productCode,
              "序列码",
              v.unit,
              v.unitId,
              v.unitList,
              v.unitValue,
              v.inventoryNum,
              null,
              v.serialNoList,
              v.num,
              v.price,
              v.totalPrice
            ),
          };
        }
        if (v.isBatch && v.isSerial) {
          obj = {
            ...new SalesreturnBatchGoods(
              v.id,
              v.productName,
              v.productId,
              v.productCode,
              "批次序列码",
              v.batchNo,
              v.productionDate,
              v.expire,
              v.expireDate,
              v.unit,
              v.unitId,
              v.unitList,
              v.unitValue,
              v.inventoryNum,
              null,
              v.num,
              v.price,
              v.totalPrice
            ),
          };
          obj.serialNoList = v.serialNoList;
          obj.isBatch = 1;
          obj.isSerial = 1;
        }
        // 设置辅助单位换算
        obj.unitConversion = backUnit(v.unitList, v.unitId);
        this.tableData.push(obj);
      });
    },
    // 过滤数据
    filtrationData(list) {
      let productList = [];
      for (let i = 0; i < list.length; i++) {
        const element = list[i];
        // if (!element.isBatch && !element.isSerial) {
        // 普通商品
        debugger;
        console.log(element);
        const state = this.verifyCommon(element);
        if (state) {
          const obj = {
            ...new commonGoods(
              element.productId,
              element.quantity,
              element.unitId,
              element.price
            ),
          };
          productList.push(obj);
          this.filtrationState = true;
        } else {
          this.filtrationState = false;
          this.$message.error(`请检查表单${element.index + 1}中的必填字段`);
          return false;
        }
        // }
        // 单批次商品
        if (element.isBatch && !element.isSerial) {
          // 批次商品
          const state = this.verifyBatch(element);
          if (state) {
            const obj = {
              ...new batchGoods(
                element.productId,
                element.batchNo,
                element.productionDate,
                element.expire,
                element.dateDue,
                element.quantity,
                element.unitId,
                element.price
              ),
            };
            productList.push(obj);
            this.filtrationState = true;
          } else {
            this.filtrationState = false;
            this.$message.error(`请检查表单${element.index + 1}中的必填字段`);
            return false;
          }
        }
        // 单序列码商品
        if (!element.isBatch && element.isSerial) {
          // 序列码
          if (element.serialNoList.length === 0)
            return this.$message.error("序列码列表不能为空");
          const state = this.verifySerial(element);
          if (state) {
            const obj = {
              ...new SerialGoods(
                element.productId,
                element.serialNoList,
                element.quantity,
                element.unitId,
                element.price
              ),
            };
            productList.push(obj);
            this.filtrationState = true;
          } else {
            this.filtrationState = false;
            this.$message.error(`请检查表单${element.index + 1}中的必填字段`);
            return false;
          }
        }
        // 批次和序列码商品
        if (element.isBatch && element.isSerial) {
          if (element.serialNoList.length === 0)
            return this.$message.error("序列码列表不能为空");
          const state = this.verifySerialAndBatch(element);
          if (state) {
            const obj = {
              // productId,batchNo,productionDate,expire,expireDate,serialNoList,num,unitId,price
              ...new BatchandSerialGoods(
                element.productId,
                element.batchNo,
                element.productionDate,
                element.expire,
                element.dateDue,
                element.serialNoList,
                element.quantity,
                element.unitId,
                element.price
              ),
            };
            productList.push(obj);
            this.filtrationState = true;
          } else {
            this.filtrationState = false;
            this.$message.error(`请检查表单${element.index + 1}中的必填字段`);
            return false;
          }
        }
      }
      return productList;
    },
    // 校验普通商品
    verifyCommon(item) {
      let flag = false;
      if (item.productId && item.unit && item.quantity) flag = true;
      return flag;
    },
    // 校验批次商品
    verifyBatch(item) {
      let flag = false;
      if (
        item.productId &&
        item.unitId &&
        item.quantity &&
        item.batchNo &&
        item.productionDate &&
        item.expire
      )
        flag = true;
      return flag;
    },
    // 校验序列码商品
    verifySerial(item) {
      let flag = false;
      if (
        item.productId &&
        item.unitId &&
        item.quantity &&
        item.serialNoList.length !== 0
      )
        flag = true;
      return flag;
    },
    verifySerialAndBatch(item) {
      let flag = false;
      if (
        item.productId &&
        item.unitId &&
        item.quantity &&
        item.batchNo &&
        item.productionDate &&
        item.expire &&
        item.serialNoList.length !== 0
      )
        flag = true;
      return flag;
    },
    // 处理最后的数据
    disposeData(list) {
      debugger;
      this.searchForm.productList = JSON.parse(JSON.stringify(list));
      if (this.searchForm.productList.every((item) => item.price !== 0)) {
        const form = {
          ...this.searchForm,
          ...this.$refs.Editable.pustorageForm,
        };
        form.thisPayment =
          form.thisPayment === undefined ? 0 : form.thisPayment;
        form.purchasePrice =
          form.purchasePrice === undefined ? 0 : form.purchasePrice;
        form.preferentialPrice =
          form.preferentialPrice === undefined ? 0 : form.preferentialPrice;
        form.handlePrice =
          form.handlePrice === undefined ? 0 : form.handlePrice;
        form.no = this.searchForm.purchaseRefundNo;
        form.price = form.handlePrice;
        if (form.thisPayment !== 0) {
          // 查看付款账户id是否为空
          if (form.accountId) {
            this.sendForm(form);
          } else {
            this.$message.error("付款账户不能为空");
            return;
          }
        } else {
          this.sendForm(form);
        }
      } else {
        this.$message.error("商品单价不能为0");
      }
    },
    // 发送表单
    sendForm(data) {
      let form = JSON.parse(JSON.stringify(data));
      form.purchaseRefundPrice = form.purchasePrice;
      delete form.purchasePrice;
      this.$confirm(`请选择下一步操作`, "提示", {
        distinguishCancelAndClose: true,
        confirmButtonText: "单据完成",
        cancelButtonText: "保存草稿",
      })
        .then(() => {
          // 单据完成
          if (this.isEdit) {
            this.editapi(form, 0);
          } else {
            this.addapi(form, 0);
          }
        })
        .catch((action) => {
          // 保存草稿
          if (action === "cancel") {
            if (this.isEdit) {
              this.editapi(form, 1);
            } else {
              this.addapi(form, 1);
            }
          }
        });
    },
    // 添加api
    addapi(form, status) {
      form.status = status;
      AddPurchaseRefund(form).then((res) => {
        if (res.code === 1) {
          this.$message.success("单据保存成功");
          this.$emit("refresh");
        } else {
          this.$message.error(res.msg);
        }
      });
    },
    // 修改api
    editapi(form, status) {
      form.id = this.data.id;
      form.status = status;
      EditPurchaseRefund(form).then((res) => {
        if (res.code === 1) {
          this.$message.success("单据保存成功");
          this.$emit("refresh");
        } else {
          this.$message.error(res.msg);
        }
      });
    },
    // 清空数据
    cleardata() {
      // 重置
      this.tableData = new Array(10)
        .fill(new SalesreturnTableGoods())
        .map((val) => {
          return { ...val };
        });
    },
  },
};
</script>